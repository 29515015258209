import { MagnifyingGlassIcon, PlusIcon, Bars3Icon } from '@heroicons/react/24/solid'
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { headerData } from './Data'

export default function Header() {
  const [isOpen, setIsOpen] = useState(false)
  const [path, setPath] = useState('')
  useEffect(() => {
    var pathArray = window.location.pathname.split('/');
    setPath(pathArray[2])
  }, [window.location.pathname])

  const delayClose = () => {
    setTimeout(function () {
      setIsOpen(false)
    }, 300);
  }
  return (
    <div className="navbar bg-none absolute top-0 z-[9999]">
      <div className="navbar-start">
        <div className={`drawer fixed left-0 top-0 ${isOpen ? 'w-full h-screen' : 'h-[64px] w-auto'}`}>
          <input id="my-drawer" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content" onClick={() => setIsOpen(true)}>
            <label htmlFor='my-drawer' className="btn btn-ghost btn-circle mt-2 ml-2">
              <Bars3Icon className='text-white w-10 h-10' />
            </label>
          </div>
          <div className="drawer-side">
            <label htmlFor="my-drawer" className="drawer-overlay !cursor-auto" onClick={delayClose}></label>

            <ul className={`relative menu p-10 pl-4 pt-16 w-80 bg-[rgba(19,20,23,.9)] text-[rgba(255,255,255,.7)] ${isOpen ? '' : 'translate-x-0'}`}>

              <label htmlFor="my-drawer" className={`absolute right-4 top-4 cursor-pointer`} onClick={delayClose}>
                <PlusIcon className='w-10 h-10 rotate-45' />
              </label>
              <div className=''>
                <a className="" href="/">陶耳 To Ear HK</a>
              </div>
              <hr className='text-gray-500 my-2' />
              {/*
              <form className='py-2 pl-3'>
                <div className='relative'>
                  <MagnifyingGlassIcon className='absolute w-6 h-6 top-1/2 left-0 -translate-y-1/2' />
                  <input type="text" className='w-full leading-[100%] text-[20px] border-b-[1px] border-[rgba(255,255,255,.5)] bg-transparent h-[48px] outline-none pl-8' />
                </div>
              </form>
              */}

              {headerData.nav?.map((item, index) => {
                return <li key={index}><a className={`w-fit hover:translate-x-2 ${path === item.link ? 'text-[rgba(255,255,255,1)]' : ''}`} href={`/${item.link}`}>{item[`navText`]}</a></li>
              })}
            </ul>
          </div>
        </div>
      </div>

      <div className="navbar-end">
        {/* <button className="btn btn-ghost btn-circle">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
        </button> */}
      </div>
    </div>
  )
}
