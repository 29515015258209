import { EnvelopeIcon, PhoneIcon, MapPinIcon } from '@heroicons/react/24/solid';

export const formQuestions = [
    {
       htmlTag: "input",
       rows: "",
       type: "text",
       placeholder: "稱呼 Name",
       name: "start",
       validations: {required: true},
       errorMessages: {required: "請填寫此欄"}
    },
    {
       htmlTag: "input",
       rows: "",
       type: "text",
       placeholder: "電郵地址 Email Address",
       name: "end",
       validations: {required: true, pattern: /^\S+@\S+$/i},
       errorMessages: {required: "請填寫此欄", pattern: "請填寫正確的電郵格式"},
    },
    {
       htmlTag: "input",
       rows: "",
       type: "text",
       placeholder: "聯絡電話 Phone Number",
       name: "summary",
       validations: {required: true, pattern: /[0-9]/},
       errorMessages: {required: "請填寫此欄", pattern: "請輸入正確的電話號碼 (只需數字)"},
    },
    {
      htmlTag: "input",
      rows: "",
      type: "text",
      placeholder: "留言 Your Message",
      name: "description",
      validations: {required: true, maxLength: 200},
      errorMessages: {required: "請填寫此欄", maxLength: "字數上限為200字"},
    }
 ]

export const commonInfo =
    {
        phone: "+8529571 3229",
        email: "toearhk@gmail.com",
    }


  export const commonContact = [
    { name: '聯絡電話：'+commonInfo.phone, href: '#', icon: PhoneIcon },
    { name: '電郵：'+commonInfo.email, href: '#', icon: EnvelopeIcon },
    { name: '地址：香港九龍觀塘興業街12號永泰中心12樓C13室, Hong Kong, Hong Kong', href: '#', icon: MapPinIcon },
  ]



  export const headerData = {
    heading_en: 'toearhk Kitchen Limited',
    serviceheadingsDup: 'toearhk Kitchen Limited',
    nav:[

      {
        navText: '案例分享',
        link: 'cases'
      },
      {
        navText: '服務項目',
        link: 'services'
      },
      {
        navText: '聯絡我們',
        link: 'contact-us'
      }
    ]


  }
  export const footerData = {
    iconList: [
      {
        iconText_en: 'Shop Address',
        iconText_zh: '門市地址',
        icon: MapPinIcon,
        link: 'https://goo.gl/maps/H1qyk3jKDqt6dLsr9'
      },
      {
        iconText_en: 'Email Us',
        iconText_zh: '電郵',
        icon: EnvelopeIcon,
        link: 'mailto:toearhk@gmail.com'
      },
      {
        iconText_en: 'Contact Us',
        iconText_zh: '致電我們',
        icon: PhoneIcon,
        link: 'tel:95713229'
      }
    ]
  }
  export const slideArrList = {
    slide1: [
      {
        title_en: "",
        subTitle_en: "",
        title_zh: "",
        subTitle_zh: "",
        img: 'images/slide1-img1.jpg'
      }
    ],
    slide2: [
      {
        title_en: "",
        subTitle_en: "",
        title_zh: "",
        subTitle_zh: "",
        img: 'images/slide2-img1.jpg'
      },
      {
        title_en: "",
        subTitle_en: "",
        title_zh: "",
        subTitle_zh: "",
        img: 'images/slide2-img2.jpg'
      },
      {
        title_en: "",
        subTitle_en: "",
        title_zh: "",
        subTitle_zh: "",
        img: 'images/slide2-img3.jpg'
      }
    ],
    slide3: [
      {
        title_en: "",
        subTitle_en: "",
        title_zh: "",
        subTitle_zh: "",
        img: 'images/slide3-img1.jpg'
      }
    ]
  }
  export const casesData = {
    serviceheadingsDup: '案例分享',
    subHeading: '',
  }

  export const servicesData = {
    serviceheadingsDup: '服務項目',
    subHeading: '',
  }