import { readClient, writeClient } from "../client"

export const getPostDetail = async (slug) => {
  const result = readClient
    .fetch(
      `*[slug.current == "${slug}"] {
      title,
      body,
      numberofsection,
      bookedperiod,
      categories[] -> {
        alias
      },
      author->,
      mainImage {
        asset -> {
          _id,
          url
        },
        alt
      },
      images[] {
        asset -> {
          _id,
          url
        },

      }
    }`
    )
  return result;
};

export const getPosts = async (catAlias) => {
  const filterCondition = (catAlias) ? `&& *[_type == "category" && alias == "${catAlias}"][0]._id in categories[]._ref` : ''
  const result = readClient
    .fetch(
      `
    *[_type=='post' ${filterCondition} ] | order(publishedAt desc){

      title,
      slug,
      body,
      numberofsection,
      bookedperiod,
      categories[0]->{alias, title},
      mainImage {
        asset -> {
          _id,
          url
        },
        alt
      },
      publishedAt
    }
    `
    )
  return result;
};


export const getCategories = async () => {
  const result = readClient
    .fetch(
      `*[_type == "category"] {

        title,
        slug,
        alias
    }`
    )
  return result;
};

export const getRelatedPosts = async (alias, title) => {
  const randomCondition = Math.floor(Math.random() * 2);
  const randomOrder = Math.floor(Math.random() * 2);
  const condition = (randomCondition === 0) ? 'title' : 'publishedAt'
  const order = (randomOrder === 0) ? 'asc' : 'desc'
  const result = readClient
    .fetch(
      `
        *[_type=='post' && title != "${title}" && *[_type == "category" && alias == "${alias}"][0]._id in categories[]._ref] | order(${condition} ${order}){
          title,
          slug,
          categories[0]->{alias, title},
          mainImage {
            asset -> {
              _id,
              url
            },
            alt
          },
          publishedAt
        }[0..2]`
    )
  return result;
};

export const getLatestPosts = async (alias, limit) => {
  const result = readClient
    .fetch(
      `*[_type=='post' && *[_type == "category" && alias == "${alias}"][0]._id in categories[]._ref] | order(publishedAt desc) { mainImage {asset -> { _id, url }, alt},publishedAt, categories[0]->{alias, title}, slug, title} [0...${limit}]`
    )
  return result;
};

export const updateServiceDetail = async (slug, selectedDate, selectedTime) => {
  const result = readClient
    .fetch(`*[slug.current == "${slug}"] { _id, bookedperiod}`)
    .then((response) => {
      var alreadyBookedPeriod = JSON.parse(response[0].bookedperiod)

      //alreadyBookedPeriod[selectedDate].push(selectedTime)
      alreadyBookedPeriod = (alreadyBookedPeriod === null) ? {} : alreadyBookedPeriod
      if (alreadyBookedPeriod !== null && alreadyBookedPeriod[selectedDate] !== null && typeof (alreadyBookedPeriod[selectedDate]) !== 'undefined') {
        alreadyBookedPeriod[selectedDate].push(selectedTime)
      } else {
        alreadyBookedPeriod[selectedDate] = [selectedTime]
      }

      const selectedPeriod = JSON.stringify(alreadyBookedPeriod)
      writeClient.patch(response[0]._id)
        .set({ bookedperiod: selectedPeriod })
        .commit()
        .then((updatedInfo) => {
          console.log('sucess')
        })
    })

  return result;
};




