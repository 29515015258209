import {BrowserRouter, Routes ,Route, Navigate} from 'react-router-dom';
import {FloatingWhatsApp} from 'react-floating-whatsapp'
import "./index.css";
import Header from "./components/Header"
import Footer from "./components/Footer"
import ContactUs from "./pages/contact-us"
import Error from "./pages/error"
import avatar from './logo.jpg'
import Services from './pages/services';
import ServicesDetail from './pages/services-detail';
import Cases from './pages/cases';
import CaseDetail from './pages/case-detail';


function App() {
  return (
    <BrowserRouter >
        <Header />
        <Routes>
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="services" element={<Services />} />
            <Route path="services/:detail" element={<ServicesDetail />} />
            <Route path="cases" element={<Cases />} />
            <Route path="cases/:detail" element={<CaseDetail />} />
            <Route path="*" element={<Error />} />
            <Route path="/" element={<Navigate replace to="services" />} />
        </Routes>


      <FloatingWhatsApp
        className="z-20"
        phoneNumber='+852 9571 3229'
        accountName='陶耳採耳專門店 To Ear HK'
        allowClickAway
        avatar={avatar}
        notification
        notificationDelay={30000}
        chatMessage={`Hello, May I help you?`}
      />
        <Footer />
    </BrowserRouter>
  )
}

export default App
