import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { getPostDetail, getRelatedPosts } from "../services"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Error from './error'
import LatestPost from '../components/LatestPost'
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import DocumentMeta from 'react-document-meta';
import BlockContent from "@sanity/block-content-to-react"

export default function CaseDetail() {
    var meta = []

    const { detail } = useParams()
    const [postDetail, setPostDetail] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [relatedPosts, setRelatedPosts] = useState([])

    const [categoryAlias, setCategoryAlias] = useState([])
    const [ArticleTitle, setArticleTitle] = useState([])
    useEffect(() => {
        setIsLoading(true)
        getPostDetail(detail).then((result) => {
            setPostDetail(result)
            setIsLoading(false)
            setCategoryAlias(result[0].categories[0].alias);
            setArticleTitle(result[0].title)
        })
    }, [])
    useEffect(() => {
        getRelatedPosts(categoryAlias, ArticleTitle).then((related3Posts) => {
            setRelatedPosts(related3Posts)
        });
    }, [ArticleTitle])

    if (!isLoading) {
        if (postDetail.length === 0) {
            return <Error />
        } else {
            meta = {
                title: `陶耳採耳專門店To Ear HK - ${postDetail[0][`title`]} | 香港專業採耳| 免費線上預約排程系統`,
                canonical: `https://www.toearhk.com/cases/${detail}`,
                meta: {
                    charset: 'utf-8',
                    name: {
                        title: `${postDetail[0][`title`]} - 陶耳 ToEarHK`,
                        keywords: `陶耳, toearhk`,
                        description: `陶耳 ToEarHK`,
                    }
                }
            };
        }
    }
    return (
        <DocumentMeta {...meta}>

            <div className=' bg-mobile-cover md:bg-desktop-cover  bg-fixed bg-center'>
                <div className='w-full h-full min-h-[650px] overflow-hidden relative'>

                </div>
                <div className='bg-[rgba(0,0,0,.8)] py-8 px-5 md:p-12 min-h-screen'>
                    {isLoading ? (
                        <div>載入中...</div>
                    ) :
                        <div>
                            <div className=" relative text-white">

                                <div className=" py-16 px-5 mx-auto w-full">
                                    <Link className='text-white flex mb-4' to={`/cases`}>
                                        <ArrowLeftIcon className='w-6 h-6 mr-2' />
                                        返回案例分享
                                    </Link>
                                    <div className="block lg:flex">
                                        <div className='w-full lg:w-3/4'>
                                            <div className="rounded-lg flex shadow-md">
                                                <div className="flex flex-col items-start pb-10">
                                                    <img className="w-24 h-24 my-3 rounded-full shadow-lg" src={postDetail[0].mainImage !== null ? postDetail[0].mainImage.asset.url : "../../logo512.png"} alt={postDetail[0][`title`]}/>
                                                    <h5 className="mb-1 text-xl font-medium text-white">{(postDetail[0].author !== null) ? postDetail[0].author.name : ''}</h5>
                                                </div>
                                                <div className='my-auto ml-2'>
                                                    <h2 className='text-3xl text-white mb-8'>{postDetail[0][`title`]}</h2>
                                                </div>

                                            </div>

                                            <BlockContent
                                                blocks={postDetail[0].body}
                                                projectId="kzd3t98b"
                                                dataset="production"
                                                className="break-words"
                                            />
                                        </div>
                                        <div>
                                            <p className='pb-4'>更多個案分享</p>
                                            {relatedPosts.map((post, index) => (
                                                <div key={index} className="flex items-center w-full mb-4">
                                                    <div className="w-16 flex-none">
                                                        <img src={post.mainImage.asset.url} alt={post.title} className="align-middle rounded-full" />
                                                    </div>
                                                    <div className="flex-grow ml-4">
                                                        <a key={post.slug !== null ? post.slug : ''} href={post.slug !== null ? `/cases/${post.slug.current}` : ''} className="text-md">{post.title}</a>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <LatestPost type='service' />

                                </div>
                            </div>

                        </div>




                    }



                </div>
            </div>
        </DocumentMeta>

    )
}
