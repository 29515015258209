import { commonContact } from "../components/Data";
import DocumentMeta from 'react-document-meta';
import { Link, useParams } from "react-router-dom";

export default function ContactUs() {

   const meta = {
      title: `陶耳採耳專門店To Ear HK | 香港專業採耳| 免費線上預約排程系統`,
      canonical: `https://www.toearhk.com/contact-us`,
      meta: {
        charset: 'utf-8',
        name: {
          title: `陶耳 ToEarHK`,
          keywords: `陶耳, toearhk`,
          description: `陶耳 ToEarHK`,
        }
      }
    };
   return (
      <DocumentMeta {...meta}>

      <div className=' bg-mobile-cover md:bg-desktop-cover  bg-fixed bg-center'>
      <div className='w-full h-full min-h-[650px] overflow-hidden relative'></div>
         <div className="bg-[rgba(0,0,0,.8)] relative text-white">

            <div className="max-w-[1140px] py-12 px-5 mx-auto w-full">
               <h1
                  className="
                  mb-4
                  uppercase
                  font-bold
                  text-xl
                     "
               >
                  聯絡我們
               </h1>
               <div className="flex flex-wrap lg:justify-between -mx-4">
                  <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-5 md:gap-10">
                     <div className="mb-12 lg:mb-0">
                        {commonContact.map((item, index) => (
                           <div key={index} className="flex mb-2 max-w-[370px] w-full">
                              <div className="
                              max-w-[60px]
                              sm:max-w-[70px]
                              w-full
                              flex
                              items-center
                              justify-center
                              mr-6
                              overflow-hidden
                              bg-[#3056d3] bg-opacity-5
                              text-[#3056d3]
                              rounded
                              " >
                                 <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                              </div>
                              <div className="w-full">
                                 <p className="text-base text-body-color">
                                    {item[`name`]}
                                 </p>
                              </div>
                           </div>
                        ))}

                     </div>
                     <div className="mt-4 mx-auto"><div className="gmap_canvas"><iframe width="350" height="250" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14764.691849969415!2d114.2072837!3d22.3092975!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340401576c7aad05%3A0x6b109ce730846de5!2z6Zm26ICz5o6h6ICz5bCI6ZaA5bqXIFRvIEVhcg!5e0!3m2!1szh-TW!2shk!4v1671164910779!5m2!1szh-TW!2shk" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe></div></div>
                  </div>
                  {/*<Form />*/}
               </div>
            </div>
         </div>

      </div>
      </DocumentMeta>

   )
}




