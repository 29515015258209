import React, { useState, useEffect, useRef } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { servicesData } from "../components/Data";
import { getPosts } from "../services"
import DocumentMeta from 'react-document-meta';



export default function Services() {
    const sliderRef = useRef(null)
    const [services, setServices] = useState([])
    useEffect(() => {
        getPosts('service').then((result) => {
            setServices(result)
        })
    }, [])

    const meta = {
        title: `陶耳採耳專門店To Ear HK | 香港專業採耳| 免費線上預約排程系統`,
        canonical: `https://www.toearhk.com/services`,
        meta: {
          charset: 'utf-8',
          name: {
            title: `陶耳 ToEarHK`,
            keywords: `陶耳, toearhk`,
            description: `陶耳 ToEarHK`,
          }
        }
      };
    const settings = {
        className: "center",
        centerMode: true,
        infinite: false,
        centerPadding: "0",
        slidesToShow: 1,
        speed: 500,
        useTransform: false,
        rows: 3,
        slidesPerRow: 3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesPerRow: 2,

                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesPerRow: 1,

                }
            }

        ]

    };
    return (
        <DocumentMeta {...meta}>
        <div className=' bg-mobile-cover md:bg-desktop-cover  bg-fixed bg-center'>
            <div className='w-full h-full min-h-[650px] overflow-hidden relative'>

            </div>

            <div className="bg-[rgba(0,0,0,.8)] p-16 style">

                <div className='h-full flex items-end md:items-center'>
                    <div className='inline-block align-middle pb-9 text-white'>
                        <h1>{servicesData[`heading`]}</h1>
                        <span>{servicesData[`subHeading`]}</span>
                    </div>
                </div>
                <Slider {...settings} ref={sliderRef}>
                    {services.map((item, index) => {
                        return (
                            <div key={index} className="align-top text-left inline-block w-1/3 p-1">
                                <div className="overflow-hidden">
                                    <Link className="group" to={item.slug !== null ? item.slug.current : ''}>
                                            <img className="aspect-video w-full block transition-all duration-300 group-hover:scale-105" src={item.mainImage !== null ? item.mainImage.asset.url : "../../logo512.png"} alt="thumbnail" />

                                    </Link>
                                </div>
                                <div className="h-[100px] bg-[#131417] p-4 md:p-10">

                                    <h3 className="text-lg font-light">
                                        <Link className="text-type-1 text-white text-hover" to={item.slug !== null ? item.slug.current : ''}>{item[`title`]}</Link>
                                    </h3>
                                </div>

                            </div>
                        )
                    })}



                </Slider>
            </div>
        </div>
        </DocumentMeta>
    )
}
