import { getLatestPosts } from "../services";
import { useState, useEffect } from "react"


export default function LatestPost(posts) {
  const [recentPosts, setrecentPosts] = useState([])

  useEffect(() => {
    getLatestPosts(posts.type, 4).then((latestPosts) => {
      setrecentPosts(latestPosts)
    });
  }, [])


  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 md:gap-10" >
    {recentPosts.map((item, index) => (
        <a  key={index} href={`/services/${item.slug.current}`} className="py-2 transition-colors duration-200 relative block hover:text-gray-900 text-gray-500">
          <img  src={item.mainImage !== null ? item.mainImage.asset.url : "../../logo512.png"} alt={item.title}/>
          {item.title}
          </a>
    ))}
    </div>

  )
}
