import { footerData } from "./Data";
import { Link, useParams } from "react-router-dom"
import { SocialIcon } from 'react-social-icons';


export default function Footer() {
    const { lang } = useParams()
    const currentYear = new Date().getUTCFullYear();
    return (
        <footer className="relative z-99 p-10 bg-black">
            <div className="w-full text-gray-500">
                <ul className="flex flex-col md:flex-row justify-between items-center gap-6 max-w-2xl mx-auto py-5">
                    {footerData.iconList.map((item, index) => {
                        return (
                            <li key={index}>
                                <a className="text-center gap-3 group" target="_blank" href={item.link}>
                                    <item.icon className="my-2 mx-auto w-8" />
                                    <p className="text-type-1  group-hover:text-[#fff]">{item[`iconText`]}</p>
                                </a>
                            </li>
                        )
                    })}

                </ul>
                <ul className="flex flex-col items-center md:flex-row justify-between gap-8 uppercase text-type-1 max-w-4xl mx-auto py-5">
                    <li className="mb-10">
                        <ul className="flex flex-col gap-3">
                            <a href="cases" className="pb-2 text-[#fff]">案例分享</a>
                        </ul>
                    </li>
                    <li className="mb-10">
                        <ul className="flex flex-col gap-3">
                            <a href="services" className="pb-2 text-[#fff]">服務項目</a>


                        </ul>
                    </li>
                    <li className="mb-10">
                        <ul className="flex flex-col gap-3">
                            <a href="contact-us" className="pb-2 text-[#fff]">聯絡我們</a>
                        </ul>
                    </li>

                </ul>
                <div className="flex flex-col justify-between p-5 border-t-[1px] border-[#23536c] gap-7 lg:flex-row">
                    <div className="flex flex-col sm:flex-row sm:items-center gap-5 sm:gap-3">


                    </div>
                    <div className="flex flex-col gap-4 text-type-1 text-xs pr-16">
                        <div className="flex items-center gap-2">
                            <a className="inline-block text-xs text-type-1 text-hover" href="/en">EN</a>
                            <a className="pl-3 inline-block text-xs text-type-1 text-hover" href="/zh">繁</a>
                            <span>|</span>
                            <div className="flex items-center gap-4">
                                <span className="text-type-1">{lang === 'en' ? 'Follow Us' : '關注我們:'}</span>
                                <SocialIcon url="https://www.facebook.com/people/%E9%99%B6%E8%80%B3%E6%8E%A1%E8%80%B3%E5%B0%88%E9%96%80%E5%BA%97-To-Ear-HK/100083144926888" network="facebook" />
                                <SocialIcon url="https://www.instagram.com/toearhk/" network="instagram" />
                            </div>

                        </div>
                        <div className="flex flex-col sm:flex-row sm:items-center gap-2 text-type-1 text-hover">

                        </div>
                        <span>©{currentYear} 版權所有 - toearhk Kitchen Limited</span>

                    </div>
                </div>
            </div>

        </footer>
    );

}
