import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import {  getPostDetail, getPosts, updateServiceDetail } from "../services"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Error from './error'
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import DayTimePicker from '@mooncake-dev/react-day-time-picker';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import BlockContent from "@sanity/block-content-to-react"
import DocumentMeta from 'react-document-meta';

import emailjs from "@emailjs/browser";
export default function ServiceDetail() {
    var meta = []

    const form = useRef();
    const [bookedPeriod, setBookedPeriod] = useState([])
    const [allBookedPeriod, setAllBookedPeriod] = useState({})
    const [allBookedSection, setAllBookedSection] = useState({})
    const [numberOfSection, setNumberOfSection] = useState(3)
    const [isScheduling, setIsScheduling] = useState(false);
    const [isScheduled, setIsScheduled] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');

    const { detail } = useParams()
    const [postDetail, setPostDetail] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        getPosts('service').then((result)=>{
            var tempAllBookedPeriod = allBookedPeriod
            var tempAllBookedSection = allBookedSection
            result.map((item, index) => {

                if (item.bookedperiod != null && item.bookedperiod != "undefined") {
                    tempAllBookedPeriod[item.slug.current] = JSON.parse(item.bookedperiod)
                    tempAllBookedSection[item.slug.current] = item.numberofsection
                }

            })
            setAllBookedPeriod(tempAllBookedPeriod)
            setAllBookedSection(tempAllBookedSection)
        })
        getPostDetail(detail).then((result) => {
            setPostDetail(result)
            if (result[0] != null && result[0] != "undefined") {
                setBookedPeriod(JSON.parse(result[0].bookedperiod))
                setNumberOfSection(result[0].numberofsection)
            }
            setIsLoading(false)
        })
    }, [isScheduling])

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const onSubmit = data => {
        if(isScheduling){
            sendToEmail()
            reset()
            setIsScheduling(false)
            setIsScheduled(true)
        }

    };

    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [summary, setSummary] = useState('')
    const [displayStart, setDisplayStart] = useState('')
    const [displayEnd, setDisplayEnd] = useState('')

    const theme = {
        primary: 'gold',
        secondary: 'slategrey',
        background: '#111', // This should match the container background
        buttons: {
            disabled: {
                color: '#333',
                background: '#f0f0f0'
            },
            confirm: {
                color: '#fff',
                background: 'slategrey',
                hover: {
                    color: '',
                    background: 'lightslategrey'
                }
            }
        },
        feedback: {
            success: {
                color: '#29aba4'
            },
            failed: {
                color: '#eb7260'
            }
        }
    };
    const DarkContainer = styled.div`
        width: 100%;
        padding: 1em;
        color: #fff;
        border-radius: 5px;
        text-align: center;
      `;
    function timeSlotValidator(slotTime) {
        const currentSelectedDate = slotTime.getFullYear() + '-' + slotTime.getMonth() + '-' + slotTime.getDate()
        var isValid = true

        const allPeriod = []

        Object.keys(allBookedPeriod).map((services, index) => {
            if(allBookedPeriod[services][currentSelectedDate] !== null && typeof(allBookedPeriod[services][currentSelectedDate]) !== 'undefined'){
                allBookedPeriod[services][currentSelectedDate].map((item, index) => {
                    const sectionOfEachService = allBookedSection[services]
                    allPeriod[item] = sectionOfEachService
                })
            }
        })

        function sortObject(obj) {
            return Object.keys(obj).sort().reduce(function (result, key) {
                result[key] = obj[key];
                return result;
            }, {});
        }

        const allPeriodArray = sortObject(allPeriod)
        var lastBookTime = 0
        var lastSection = 0
        Object.keys(allPeriodArray).map((timeSlot, index) => {

            const bookSlot = timeSlot.split("-")
            const bookTime = new Date(
                slotTime.getFullYear(),
                slotTime.getMonth(),
                slotTime.getDate(),
                bookSlot[0],
                bookSlot[1],
                0
            );
            var allSectionOfEachService = allPeriodArray[timeSlot]

            if (slotTime.getTime() >= bookTime.getTime() && slotTime.getTime() < (bookTime.getTime() + 900000 * allSectionOfEachService)) {
                isValid = false
            }

            if(lastBookTime !== 0 && lastBookTime !== bookTime){

                const lastBookTimeEndTime = lastBookTime.getTime() + 900000 * lastSection

                if((bookTime.getTime() - lastBookTimeEndTime) < (900000 * numberOfSection)){
                    console.log(timeSlot)
                    console.log(lastBookTime)

                    console.log(allSectionOfEachService) // this should be 3!!!!!!!

                    if(slotTime.getTime() >= lastBookTimeEndTime && slotTime.getTime() < bookTime.getTime()){

                        isValid = false
                    }
                }
            }


            lastBookTime = bookTime
            lastSection = allPeriodArray[timeSlot]

        })

        return isValid;
    }

    const handleScheduled = dateTime => {
        setSelectedDate(dateTime.getFullYear() + '-' + dateTime.getMonth() + '-' + dateTime.getDate())
        setSelectedTime(dateTime.getHours() + '-' + dateTime.getMinutes())
        setIsScheduling(true)
        const month = dateTime.getMonth() + 1
        const startTime = dateTime.getFullYear() + '-' + month + '-' + dateTime.getDate() + 'T' + dateTime.getHours() + ':' + dateTime.getMinutes() + ':00'
        const endMinute = (dateTime.getMinutes() + 40) % 60
        const endHour = dateTime.getHours() + Math.floor((dateTime.getMinutes() + 40) / 60)
        const endTime = dateTime.getFullYear() + '-' + month + '-' + dateTime.getDate() + 'T' + endHour + ':' + endMinute + ':00'
        setStart(startTime)
        setEnd(endTime)
        setDisplayStart(dateTime.getFullYear() + '年' + month + '月' + dateTime.getDate() + '日 ' + dateTime.getHours() + '時' + dateTime.getMinutes() + '分')
        setDisplayEnd(dateTime.getFullYear() + '年' + month + '月' + dateTime.getDate() + '日 ' + endHour + '時' + endMinute + '分')
        setSummary(postDetail[0]['title'])

    };

    function sendToEmail() {
        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_GMAIL_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_EMAIL_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY).then(
                (result) => {
                    updateServiceDetail(detail, selectedDate, selectedTime).then((result) => {
                        alert("預約成功");
                        window.location.href = "/services"
                    })
                },
                (error) => {
                    alert(`抱歉！預約不成功`);
                }
            );
    };

    if (!isLoading) {
        if (postDetail.length === 0) {
            return <Error />
        } else {
            meta = {
                title: `陶耳採耳專門店To Ear HK - ${postDetail[0][`title`]} | 香港專業採耳| 免費線上預約排程系統`,
                canonical: `https://www.toearhk.com/services/${detail}`,
                meta: {
                    charset: 'utf-8',
                    name: {
                        title: `${postDetail[0][`title`]} - 陶耳 ToEarHK`,
                        keywords: `陶耳, toearhk`,
                        description: `陶耳 ToEarHK`,
                    }
                }
            };
        }
    }

    return (
        <DocumentMeta {...meta}>

            <div className=' bg-mobile-cover md:bg-desktop-cover  bg-fixed bg-center'>
                <div className='w-full h-full min-h-[650px] overflow-hidden relative'>

                </div>
                <div className='bg-[rgba(0,0,0,.8)] p-4 md:p-8 min-h-screen'>
                    {isLoading ? (
                        <div>載入中...</div>
                    ) :
                        <div>

                            <div className=" relative text-white">

                                <div className=" py-16 px-5 mx-auto w-full">
                                    <Link className='text-white flex mb-4' to={`/services`}>
                                        <ArrowLeftIcon className='w-6 h-6 mr-2' />
                                        返回案例分享
                                    </Link>
                                    <h2 className='text-3xl text-white mb-8'>{postDetail[0][`title`]}</h2>
                                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 md:gap-10'>
                                        <div className="">

                                            <img className='w-1/2 mx-auto block mb-9' src={postDetail[0].mainImage !== null ? postDetail[0].mainImage.asset.url : "../../logo512.png"} alt={postDetail[0][`title`]} />
                                            <BlockContent
                                                blocks={postDetail[0].body}
                                                projectId="kzd3t98b"
                                                dataset="production"
                                                className="break-words"
                                            />
                                        </div>
                                        {!isScheduling && !isScheduled? (
                                            <DarkContainer>
                                                <DayTimePicker
                                                    isLoading={false}
                                                    isDone={isScheduling}
                                                    timeSlotSizeMinutes={15}
                                                    timeSlotValidator={timeSlotValidator}
                                                    onConfirm={handleScheduled}
                                                    theme={theme}
                                                    confirmText="預約時間"
                                                />
                                            </DarkContainer>
                                        ) :
                                            <div className="bg-gray-700 w-full lg:w-2/3 mx-auto relative rounded-lg p-8 sm:p-12 shadow-lg">
                                                <form onSubmit={handleSubmit(onSubmit)} ref={form}>

                                                    <p>開始時間 (24小時報時制):</p>
                                                    <p className='w-full mt-2 mb-4 rounded bg-gray-500 py-3 px-4 text-body-color text-base outline-none focus-visible:shadow-none focus:border-primary'>{displayStart}</p>
                                                    <input className="hidden w-full mt-2 mb-4 rounded bg-gray-500 py-3 px-4 text-body-color text-base outline-none focus-visible:shadow-none focus:border-primary" type="text" name="start" defaultValue={start} />
                                                    <p >結束時間 (24小時報時制):</p>
                                                    <p className='w-full mt-2 mb-4 rounded bg-gray-500 py-3 px-4 text-body-color text-base outline-none focus-visible:shadow-none focus:border-primary'>{displayEnd}</p>
                                                    <input className="hidden w-full mt-2 mb-4 rounded bg-gray-500 py-3 px-4 text-body-color text-base outline-none focus-visible:shadow-none focus:border-primary " type="text" name="end" defaultValue={end} />
                                                    <p>服務內容:</p>
                                                    <p className='w-full mt-2 mb-4 rounded bg-gray-500 py-3 px-4 text-body-color text-base outline-none focus-visible:shadow-none focus:border-primary'>{summary}</p>
                                                    <input className="hidden w-full mt-2 mb-4 rounded bg-gray-500 py-3 px-4 text-body-color text-base outline-none focus-visible:shadow-none focus:border-primary " type="text" name="summary" defaultValue={summary} />
                                                    <span>電話:</span>
                                                    <input className="w-full mt-2 mb-4 rounded bg-gray-500 py-3 px-4 text-body-color text-base outline-none focus-visible:shadow-none focus:border-primary" required pattern="[0-9]{8}" type="tel" name="description" />

                                                    <div>
                                                        <button
                                                            type="submit"
                                                            className="
                                                    w-full
                                                    text-white
                                                    bg-blue-800
                                                    rounded
                                                    border border-primary
                                                    p-3
                                                    transition
                                                    hover:bg-opacity-90
                                                    "
                                                        >
                                                            確認提交
                                                        </button>
                                                    </div>
                                                </form>
                                                <div>
                                                    <span className="absolute -top-10 -right-9 z-[-1]">
                                                        <svg
                                                            width="100"
                                                            height="100"
                                                            viewBox="0 0 100 100"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                                                                fill="#3056D3"
                                                            />
                                                        </svg>
                                                    </span>

                                                </div>
                                            </div>
                                        }

                                    </div>


                                </div>
                            </div>

                        </div>




                    }



                </div>
            </div>
        </DocumentMeta>

    )
}
