import React, { useState, useEffect, useRef } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useParams } from "react-router-dom";
import { casesData } from "../components/Data";
import { getPosts } from "../services"
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import DocumentMeta from 'react-document-meta';



export default function Cases() {
    const sliderRef = useRef(null)
    const [cases, setCases] = useState([])
    const [casesDup, setCasesDup] = useState([])
    const meta = {
        title: `陶耳採耳專門店To Ear HK | 香港專業採耳| 免費線上預約排程系統`,
        canonical: `https://www.toearhk.com/cases`,
        meta: {
          charset: 'utf-8',
          name: {
            title: `陶耳 ToEarHK`,
            keywords: `陶耳, toearhk`,
            description: `陶耳 ToEarHK`,
          }
        }
      };
    useEffect(() => {
        getPosts('case').then((result) => {
            setCases(result)
            setCasesDup(result)
        })
    }, [])

    const handleInputChange = (keyword) => {
        if (keyword == '') {
            setCases(casesDup)
            sliderRef.current.slickGoTo(0, true)
        }else {
            setCases(casesDup.filter(item => item[`title`].toLowerCase().includes(keyword.toLowerCase())))
        }
    }

    const settings = {
        className: "center",
        centerMode: true,
        infinite: false,
        centerPadding: "0",
        slidesToShow: 1,
        speed: 500,
        useTransform: false,
        rows: 3,
        slidesPerRow: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesPerRow: 2,

                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesPerRow: 1,

                }
            }

        ]

    };
    return (
        <DocumentMeta {...meta}>

        <div className=' bg-mobile-cover md:bg-desktop-cover  bg-fixed bg-center'>
            <div className='w-full h-full min-h-[650px] overflow-hidden relative'>

            </div>

            <div className="bg-[rgba(0,0,0,.8)] p-16 style">
                <div className="relative flex mb-7 border-b-[1px] border-[rgba(255,255,255,.5)]"><MagnifyingGlassIcon className='text-white my-auto w-6 h-6' />
                    <input type="text" placeholder = '搜尋案例' onChange={(e) => handleInputChange(e.target.value)} className="w-full leading-[100%] text-[20px] text-white  bg-transparent h-[48px] outline-none pl-4" />
                </div>
                <div className='h-full flex items-end md:items-center'>
                    <div className='inline-block align-middle pb-9 text-white'>
                        <h1>{casesData[`heading`]}</h1>
                        <span>{casesData[`subHeading`]}</span>
                    </div>
                </div>
                <Slider {...settings} ref={sliderRef}>
                    {cases.map((item, index) => {
                        return (
                            <div key={index} className="align-top text-left inline-block w-1/3 p-1">
                                <div className="overflow-hidden">
                                    <Link className="group" to={`${item.slug.current}`}>
                                        <img className="aspect-video w-full block transition-all duration-300 group-hover:scale-105" src={item.mainImage.asset.url} alt="thumbnail" />
                                    </Link>
                                </div>
                                <div className="h-[100px] bg-[#131417] p-4 md:p-10">

                                    <h3 className="text-lg font-light">
                                        <Link className="text-type-1 text-white text-hover" to={`${item.slug.current}`}>{item[`title`]}</Link>
                                    </h3>
                                </div>

                            </div>
                        )
                    })}



                </Slider>
            </div>


        </div>
        </DocumentMeta>

    )
}
