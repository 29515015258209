import client from "@sanity/client"

export const readClient = client({
  projectId: "kzd3t98b",
  dataset: "production",
  useCdn: false,
  apiVersion: "2022-12-01",
})

export const writeClient = client({
  projectId: 'kzd3t98b',
  dataset: 'production',
  apiVersion: "2022-12-15",

  token: "skNWTrJiLWae9BwyLIMrZoG5LBqIlQmby46DE4sPkzO6LnLUYnYHJpeOuaYKmdBAJdkclT1dR7Jr8MqZlXrgWOnly5AbHQA3iITYTBqlIXmEp5Y8Ib1DnV9CYCSW5OaRf60ZM388mtluUGvTRr3tFJN9u2lY75BJGTf3aIrJf3etvg8n5K7j",
  useCdn: false
})